import React from "react";
import { Link } from "@reach/router";
import Notfound from "../Assets/Images/404.gif";

export default function Error404() {
  return (
    <div className="container" style={{ textAlign: "center" }}>
      <h1>Awch my gorgeous friend on the internet</h1>
      <h2>
        You just hit a route that doesn't exist .... Go to{" "}
        <Link to="/">Home</Link> or find me on
      </h2>
      <div className="links">
        <a href=" https://github.com/labaran1">
          {" "}
          <i class="fab fa-github"></i> <span>Github</span>
        </a>
        <a href="https://twitter.com/labaranlabs">
          <i class="fab fa-twitter"></i> <span>twitter</span>
        </a>
        <a href="https://www.youtube.com/channel/UCLINuI_UuQ3KMY-QQYGO1Lw?sub_confirmation=1">
          {" "}
          <i class="fab fa-youtube"></i> <span>Youtube</span>
        </a>
        <a href=" https://www.linkedin.com/in/adam-labaran-111358181/">
          {" "}
          <i class="fab fa-linkedin"></i> <span>LinkedIn</span>
        </a>
      </div>

      <img src={Notfound} alt="" />
    </div>
  );
}
