import React from "react";
import ME from "../Assets/Images/m1.jpg";

export default function Home() {
  return (
    <div>
      <div className="imgContainer">
        <img className="myImg" src={ME} alt="" />
      </div>

      <div className="quoteContainer">
        <blockquote>
          Fell in love with a dumb machine that does exactly what I instruct it
          to do, when and how. Hoping our relationship makes the world a better
          place. || <span>SOFTWARE ENGINEER</span>
        </blockquote>
        <p>Find me on!!!</p>
        <div className="links">
          <a href=" https://github.com/labaran1">
            {" "}
            <i class="fab fa-github"></i> <span>Github</span>
          </a>
          <a href="https://twitter.com/labaranlabs">
            <i class="fab fa-twitter"></i> <span>twitter</span>
          </a>
          <a href="https://www.youtube.com/channel/UCLINuI_UuQ3KMY-QQYGO1Lw?sub_confirmation=1">
            {" "}
            <i class="fab fa-youtube"></i> <span>Youtube</span>
          </a>
          <a href=" https://www.linkedin.com/in/adam-labaran-111358181/">
            {" "}
            <i class="fab fa-linkedin"></i> <span>LinkedIn</span>
          </a>
        </div>
        <div className="linkBlog ">
          <a href="https://blog.labaranlabs.com/" className="blogLink">
            Visit Blog
          </a>
        </div>
      </div>
    </div>
  );
}
