import React from "react";
import "./App.css";
import Home from "./Component/Home";
import { Router } from "@reach/router";
import Error404 from "./Component/Error404";
function App() {
  return (
    <Router>
      <Home path="/" />
      <Error404 default />
    </Router>
  );
}

export default App;
